<!--  -->
<template>
  <div style="width: 1600px">
    <!--日期 发消息-->
    <div style="display: flex;justify-content: flex-end;width: 98%;margin-bottom: 10px">
      <el-date-picker
          v-model="date"
          align="right"
          type="month"
          :placeholder="$t('ChooseDate')"
          @change="selectDay"
          value-format="yyyy-MM"
      >
      </el-date-picker>
      <el-button icon="el-icon-message" style="margin-left: 20px;font-size: 16px;line-height: 20px"
                 @click="sendDialog = true">{{ $t('SendMsg') }}
      </el-button>
    </div>
    <!--群发消息-->
    <div class="sysMsg">
      {{ $t('GroupMessage') }}
    </div>
    <!--消息列表-->
<!--    <div class="msgs" v-for="msg in msgData" :key="msg.id">
      <h2 style="margin-bottom: 10px">{{ msg.title }}</h2>
      &lt;!&ndash;<span class="content" ref="content">{{ msg.content | ellipsis }}</span>&ndash;&gt;
      <pre class="content" ref="content">{{ msg.content | ellipsis }}</pre>
&lt;!&ndash;      <span @click="readMsg(msg.title,msg.content,msg.sendTime)" v-show="msg.content && msg.content.length > 326"
            style="cursor: pointer;align-self: flex-end">{{ $t('ReadMore') }}</span>&ndash;&gt;
      <span @click="readMsg(msg.title,msg.content,msg.sendTime)" v-show="needHide"
            style="cursor: pointer;align-self: flex-end">{{ $t('ReadMore') }}</span>
      <div class="msgTime">{{ msg.sendTime }}</div>
    </div>-->
    <!--消息列表-->
    <div class="msgs" v-for="msg in msgData" :key="msg.id">
      <TooHighHide :textContent="msg"  @readDialog="readMsg"> </TooHighHide>
    </div>
    <div class="no-data" v-if="!msgData.length">
      <span>{{$t('NoData')}}</span>
    </div>
    
    <!--分页-->
    <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        v-if="msgData.length"
        style="margin: 10px auto 10px 20px">
    </el-pagination>
    
    <!--查看消息弹窗-->
    <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="msgDialog" append-to-body center>
      <el-form :model="dialogContentForm" v-if="msgDialog">
        <div class="msgsDialog">
          <h2 style="margin-bottom: 10px">{{ dialogContentForm.title }}</h2>
          <!--<div class="content">{{ dialogContentForm.content }}</div>-->
          <pre class="content">{{ dialogContentForm.content }}</pre>
          <span class="msgTime">{{ dialogContentForm.time }}</span>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="msgDialog = false">{{$t('Confirm')}}</el-button>
      </div>
    </el-dialog>
    
    <!--发送消息弹窗-->
    <el-dialog :close-on-click-modal="false" :visible.sync="sendDialog" :show-close="false" :title="$t('SendMsg')" append-to-body center>
      <el-form :model="sendParam" class="sendDialog" :rules="rules" ref="sendForm" v-if="sendDialog">
        <el-form-item prop="title">
          <label>{{ $t('Title') }}</label>
          <el-input v-model="sendParam.title"></el-input>
        </el-form-item>
        <el-form-item prop="content">
          <label>{{ $t('Content') }}</label>
          <el-input
              type="textarea"
              :rows="10"
              resize="none"
              maxlength="2000"
              show-word-limit
              v-model="sendParam.content">
          </el-input>
          <!--<span class="gray"> {{$t('WordLimit')}}:{{ enter }}/2000</span>-->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="sendDialog">
        <el-button @click="cancel">{{$t('Cancle')}}</el-button>
        <el-button type="primary" :loading="loading" @click="sendMsg">{{$t('Confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/fetch/api'
import TooHighHide from "@/views/MessagerMenu/TooHighHide.vue";

export default {
  components: {
    TooHighHide,
  },
  data() {
//            读取本地个人信息
    const data = JSON.parse(localStorage.getItem('cacheChamber')).adminData
    return {
      chamberId: data.chamberId,
      readBtn: false,
      msgDialog: false,
      sendDialog: false,
      date: '',
      msgData: [],
      total: 0,
      pageSize: 5,
      currentPage: 1,
      needHide:false,
      
      initMes: {
        chamberId: data.chamberId,
        page: 1,
      },
      
      dialogContentForm: {
        title: '',
        content: '',
        time: '',
      },
      
      sendParam: {
        chamberId: data.chamberId,
        adminId: data.id,
        title: '',
        content: '',
      },

      loading: false,
      
      rules: {
        title: [{required: true, message: this.$t('InputTitle'), trigger: 'blur'}],
        content: [{required: true, message: this.$t('InputContent'), trigger: 'blur'}]
      },
    };
  },
  watch:{
    'sendParam.title':{
      handler(newVal, oldVal){
        if(newVal.length > 70){
          this.$message.closeAll()
          this.$message.error(this.$t('TitleLong'))
          this.sendParam.title = newVal.trim().slice(0,70)
          // console.log('newVal ->',newVal.slice(0,50))
        }
      },
      deep:true,
      immediate:true
    },
    'sendParam.content':{
      handler(newVal, oldVal){
        console.log('newVal.length ->',newVal.length)
        if(newVal.length > 2000){
          this.$message.closeAll()
          this.$message.error(this.$t('ContentLong'))
          this.sendParam.content = newVal.trim().slice(0,2000)
          // console.log('newVal ->',newVal.slice(0,50))
        }
      },
    },
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 326) {
        return value.slice(0, 326) + '...'
      }
      return value
    }
  },
  mounted() {
    this.initMsg(1)
  },
  computed: {
    content(value) {
      if (!value) return ''
      if (value.length > 326) {
        return value.slice(0, 326) + '...'
      }
    },
    enter() {
      return this.sendParam.content.length
    },
  },
  methods: {
//            初始化
    initMsg(page) {
      this.initMes.page = page
      this.date === null ? delete this.initMes.date : this.initMes.date = this.date
      api.getMsgList(this.initMes).then(res => {
        // console.log('请求成功', res.msg)
        this.msgData = res.data.pageList
        this.total = res.data.total
      })
    },

//            阅读全文弹窗
    readMsg(title, desc, time) {
      this.msgDialog = true;
      this.dialogContentForm.title = title;
      this.dialogContentForm.content = desc;
      this.dialogContentForm.time = time;
    },

//            当选择时间
    selectDay() {
      // console.log('this.date ->',this.date)
      // this.date === null ? delete this.initMes.date : this.initMes.date = this.date
      this.initMsg(1);
      delete this.initMes.date
    },

//            群发消息
    sendMsg() {
      this.$refs['sendForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          api.sendMsg(this.sendParam).then(res => {
            console.log(res.msg)
            this.cancel()
            this.initMsg(1)
          }).finally(() => {
            this.loading = false;
          })
        } else {
          console.log('error submit')
          return false
        }
      })
    },

//            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.initMsg(currentPage)
    },
    
    //   关闭弹窗
    cancel() {
      this.sendDialog = false
      this.sendParam.title = '';
      this.sendParam.content = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.sysMsg {
  width: 98%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
  background-color: #425D7B;
  color: white;
}

.msgs {
  min-height: 150px;
  width: 98%;
  border-radius: 10px;
  background-color: #AFD2FA;
  margin: 10px 0;
  padding: 10px 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  
  button {
    float: left;
    margin-left: 20px;
    font-size: 14px;
  }
  
  .msgTime {
    align-self: flex-end;
  }
  
  .content {
    float: left;
    width: 90%;
    word-wrap: break-word;
    display: inline-block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
  }
}
pre{
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.sendDialog {
  display: flex;
  flex-flow: column nowrap;
  height: 350px;
  justify-content: space-around;
  margin-top: 20px;
  
  label {
    font-size: 20px;
  }
}

.msgsDialog {
  width: 98%;
  border-radius: 10px;
  background-color: #AFD2FA;
  margin: 10px 0;
  padding: 10px 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  
  h2{
    font-size: 22px;
    letter-spacing: 1px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .content{
    font-size: 20px;
    letter-spacing: 0.6px;
    white-space: pre-wrap;
    display: inline-block;
  }
  
  
  .msgTime {
    align-self: flex-end;
  }
}
.no-data{
  width: 98%;
  height: 200px;
  text-align: center;
  color: #909399;
  font-size: 20px;
  margin-top: 20px;
  line-height: 200px;
}
::v-deep .el-dialog__body {
  width: 100%;
  display: inline-block !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
}
</style>

